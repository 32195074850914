import React, { useContext, useEffect, useState } from 'react';
import config from "../../config";
import useHttp from '../../hooks/useHttp';
import { useLocation } from "react-router-dom";
import logo from "../../images/logo-masorden-blanco-small.svg";
import { Hidden, Drawer, List, ListItem, ListItemText } from "@gti-controls/web";
import homeStyles from "../../common/styles/Home";
import { AuthContext } from "../../providers/auth";
import { SettingsContext } from '../../providers/settings';

const Sidebar = () => {
	const location = useLocation();
	const classes = homeStyles();
	const { state: authContext } = useContext(AuthContext);
	const {
		settings,
		isSelectingAccount,
		actionSelected,
		handleMenuItemsChange,
		handleDrawerToggle,
		handleOptionChange,
		handleActionSelected,
	} = React.useContext(SettingsContext);
	const { sendRequest: fetchMenuItems } = useHttp();
	const [data, setData] = useState([]);

	useEffect(() => {
		const pathArray = location.pathname.split("/");
		const keep = (pathArray.length > 2 && location?.state) || (pathArray.length > 2 && location?.search);
		const action = (pathArray[2] || null) && location?.state?.action ? location.state.action : null;
		handleActionSelected(action);

		const setMenuItems = (menuItems) => {
			if (menuItems && menuItems.length > 0) {
				handleMenuItemsChange(menuItems);
				const urlOption = menuItems.find(option => option.VerboClassName.replace('#', '') === pathArray[1] || null);

				handleOptionChange(((urlOption) ? urlOption : menuItems[0]), false, menuItems[0], keep, action);
			}
		};

		if (authContext.user.sesionId) {
			fetchMenuItems({
				url: config.API_URL + `rights/menu/items/` + authContext.user.sesionId
			}, (data) => {
				setMenuItems(data);
				setData(data);
			});
		}

	// eslint-disable-next-line
	}, [authContext]);

	const drawerList = (
		<List>
			{data.map((option) => (
				<ListItem button className={classes.menuItems} selected={settings.menuOptionSelected.ItemID === option.ItemID}
					key={option.ItemID}
					onClick={() => handleOptionChange(option, settings.mobileDrawerOpened, null)}
				>
					<ListItemText primary={option.Texto} />
				</ListItem>
			))}
		</List>
	);

	return (
		<div>
			{(!isSelectingAccount || authContext.accountsLength === 1) && (!actionSelected) ? (
				<nav className={classes.drawer}>
					<Hidden smUp implementation="css">
						<Drawer
							variant="temporary"
							anchor={'left'}
							open={settings.mobileDrawerOpened}
							onClose={() => handleDrawerToggle()}
							classes={{
								paper: classes.drawerPaper,
							}}
							ModalProps={{
								keepMounted: true,
							}}
						>
							{drawerList}
						</Drawer>
					</Hidden>
					<Hidden xsDown implementation="css">
						<Drawer
							classes={{
								paper: classes.drawerPaper,
							}}
							variant="permanent"
							open
						>
							<div className={classes.toolbar}>
								<div className={classes.drawerLogo}>
									<img src={logo} alt="Logo" className={classes.drawerImageIcon} />
								</div>
							</div>
							{drawerList}
						</Drawer>
					</Hidden>
				</nav>
			) : null}
		</div>
	);
};

export default Sidebar;
import React, { useContext, useEffect } from 'react';
import { Backdrop as MuiBackdrop, CircularProgress } from '@gti-controls/web';
import homeStyles from "../../common/styles/Home";
import { SettingsContext } from '../../providers/settings';

let timer;

const Backdrop = () => {
	const classes = homeStyles();
	const { isLoadingContainer, handleLoadingContainerChange } = useContext(SettingsContext);

	/** Tiempo maximo para esperar UI: 15 segundos */
	useEffect(() => {
		if (isLoadingContainer) {
			timer = setTimeout(() => { handleLoadingContainerChange(false); }, 30000);
		} else {
			clearTimeout(timer);
		}
		// eslint-disable-next-line
	}, [isLoadingContainer]);

	return (
		<MuiBackdrop className={classes.backdrop} open={isLoadingContainer}>
			<CircularProgress color="inherit" size={60} />
		</MuiBackdrop>
	);
};

export default Backdrop;
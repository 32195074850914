import { useCallback, useState, useContext } from "react";
import { getSessionHandler } from "../../common/services/auth-service";
import { AuthContext } from "../../providers/auth";

/** Hook para realizar requests a un API mediante http/https */
const useHttp = () => {
    /** Hook que devuelve valores en caso de carga o error */
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    /** Clear error: Modals use */
    const clearError = useCallback(async () => {
        setError(null);
    }, []);

    const { dispatch } = useContext(AuthContext);
 
     const doLogOut = () => {
         dispatch({
             type: "LOGOUT",
             signOff: true,
         });
     };

    /** Funcion principal del Hook */
    const sendRequest = useCallback(async (requestConfig, applyData, onError) => {
        /** Se setea el valor inicial de los valores de carga y error */
        setIsLoading(true);
        setError(null);
        try {
            const sessionHandler = await getSessionHandler();
             if(!sessionHandler){
                 doLogOut();
             }
            /** Administrador de respuesta */
            const response = await fetch(
                requestConfig.url, {
                method: requestConfig.method ? requestConfig.method : "GET",
                headers: requestConfig.ignoreToken ? { ...(requestConfig.headers ? requestConfig.headers : {}) }
                    : {
                        ...(requestConfig.headers ? requestConfig.headers : {}),
                        "x-cognito-access-token": `${(sessionHandler).AccessToken}`,
                    },
                body: requestConfig.body ? requestConfig.isPlainText === true ? requestConfig.body : JSON.stringify(requestConfig.body) : null
            });

            /** Si la respuesta no es exitosa la peticion falla */
            if (!response.ok) {
                if(response.status === 401 || response.status === 403){
                    doLogOut();
                }

                const error = await response.json();
                throw error;
            }
            /** Se devuelve el objeto JSON de respuesta */
            const resp = await response.json();
            applyData(resp.data ? resp.data : resp);
        } catch (err) {
            let error;
            if ( err && err.message === "Internal Server Error" ) {
                setError('Se produjo un error al enviar o recuperar información del servidor');
            }else{
                let tmpError1 = typeof err?.error == 'string'
                let tmpError2 = typeof err?.error?.error == 'string'

                let errorCode = err?.error?.errorCode || null
                let errorAux = tmpError1 ? err?.error : tmpError2 ? err?.error?.error : null

                error = {
                    errorCode: errorCode ? errorCode : "500",
                    error: errorAux ? errorAux : 'Se produjo un error al enviar o recuperar información del servidor'
                };

                setError(error)
            }

            if ( onError) {
                onError(error);
                setIsLoading(false);
            }
        }

        setIsLoading(false);
        // eslint-disable-next-line
    }, [dispatch]);

    /** Se retorna los estados y funciones del Hook */
    return {
        isLoading,
        error,
        clearError,
        sendRequest,
    };
};

export default useHttp;
import React from 'react';
import { Route } from 'react-router-dom';
import AsyncComponent from '../AsyncComponent';

const DynamicRoute = (props) => {
	return (
		<Route
			path="/"
			isExact
			render={({ history }) => {
				const pathName = history.location.pathname.replace(/[\n\r]/g, "");

				return (
					<AsyncComponent
						path={pathName}
						search={history.location.search}
						component={props.page(pathName)}
						loading={props.loading || ''} /* 'Cargando...' */
						onError={props.onError}
						otherProps={props.props}
						history={history}
					/>
				);
			}}
		/>
	);
};

export default DynamicRoute;
import { makeStyles } from "@gti-controls/web";

const homeStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    "& .MuiFormLabel-root": {
      fontWeight: 500,
    },
    "& .MuiTypography-body1": {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "24px",
    },
  },
  toolbar: theme.mixins.toolbar,
  main: {
    flexGrow: 1,
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    position: "relative",
  },
  backdrop: {
    color: '#008d79',
    backgroundColor: 'rgba(255, 255, 255, 0.60)',
    position: "absolute",
    zIndex: theme.zIndex.drawer - 150,
  },
  content: {
    backgroundColor: '#ffffff',
    padding: theme.spacing(3),
  },
  appBar: {
    backgroundColor: '#ffffff',
    color: theme.palette.primary.main,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: 1.5,
    /*[theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${theme.spacing(31)}px)`,
      marginLeft: theme.spacing(31),
    },*/
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  logo: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    width: theme.spacing(28),
    marginLeft: theme.spacing(2),
  },
  imageIcon: {
    height: theme.spacing(4),
  },
  accountSelector: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  spacer: {
    flexGrow: 1,
  },
  accountTitle: {
    marginRight: theme.spacing(1),
    fontSize: '16px',
    fontWeight: 500,
    color: '#373740',
  },
  accountLink: {
    cursor: 'pointer'
  },
  user: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  userIcon: {
    width: 24,
    height: 24,
    color: '#bcbccc',
  },
  userTitle: {
    marginLeft: theme.spacing(1),
    fontSize: '16px',
    fontFamily: 'Roboto',
    color: '#373740',
  },
  logout: {
    marginLeft: theme.spacing(3),
    cursor: 'pointer'
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(31),
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: theme.spacing(31),
    backgroundColor: theme.palette.primary.main,
  },
  menuItems: {
    color: '#ffffff',
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    "&.Mui-selected": {
      backgroundColor: "#329be6",
    },
    "&.Mui-selected:hover": {
      backgroundColor: "#329be6",
    },
    "&:hover": {
      backgroundColor: 'rgba(255, 255, 255, 0.25)',
    }
  },
  drawerLogo: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    width: theme.spacing(25),
    marginLeft: theme.spacing(2),
  },
  drawerImageIcon: {
    height: theme.spacing(4),
  },
  backSpace: {
    marginRight: theme.spacing(2),
    display: 'inline',
  },
  backButton: {
    fontSize: '30px'
  },
  pageTitle: {
    fontWeight: 500,
    marginBottom: theme.spacing(3),
  },
  gridTitle: {
    alignSelf: 'start',
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  gridDetailIcon: {
    fontSize: '28px'
  },
  secondaryTitle: {
    marginBottom: theme.spacing(3),
  },
  secondaryTitleContainer: {
    color: '#000000',
    backgroundColor: '#e1f1fd',
    alignItems: 'center',
    fontSize: '18px',
    paddingLeft:'8px'
  },
  secondaryTitleItem: {
    [theme.breakpoints.up('xs')]: {
      paddingBottom: 20,
      paddingRight: 0
    },
    [theme.breakpoints.up('md')]: {
      paddingRight: 20,
      paddingBottom: 0
    },
    [theme.breakpoints.up('lg')]: {
      paddingRight: 20,
      paddingBottom: 0
    },
    [theme.breakpoints.up('xl')]: {
      paddingRight: 20,
      paddingBottom: 0
    }
  },
  tabContainer: {
    marginTop: theme.spacing(2),
  },
  filters: {
    fontWeight: 500,
    marginBottom: "10px",
  },
  chartContainer: {
    height: '100%',
    alignItems: "center",
    justifyContent: "center",
  },
  chartTitle: {
    alignSelf: 'start',
    width: '100%',
    marginBottom: theme.spacing(1)
  },
  chartBox: {
    width: '100%',
    backgroundColor: '#ffffff',
    border: '1px solid #9696AF',
    padding: theme.spacing(2),
    borderRadius: '4px',
    flexGrow: 1,
    overflow: 'hidden',
  },
  formFloatControl: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      padding: theme.spacing(1),
      paddingBottom: theme.spacing(0)
    },
  },
  formActionButton: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(2),
  },
  modal: {
    borderRadius: '5px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3),
  },
  modalMd: {
    maxWidth: "680px"
  },
  modalHeader: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(2),
  },
  modalIconDelete: {
    width: 40,
    height: 40,
    marginRight: theme.spacing(2),
  },
  modalHeaderText: {
    fontSize: '24px',
    fontWeight: 500,
    color: '#373740',
    flex: 1,
  },
  modalTitle: {
    marginBottom: '4px',
    fontWeight: 500,
  },
  modalClose: {
    alignItems: 'flex-start',
  },
  modalCloseButton: {
    padding: '4px',
    marginTop: '-8px',
    marginRight: '-8px',
  },
  modalContent: {
    minWidth: '552px',
    width: '60%',
    [theme.breakpoints.only('xs')]: {
      minWidth: '0px',
      width: '90% !important',
    },
  },
  modalConfirmation: {
    padding: '18px 16px 16px',
    backgroundColor: 'rgba(0,0,0, 0.08)',
    borderRadius: '8px',
  },
  modalConfirmationDanger: {
    padding: '18px 16px 16px',
    backgroundColor: '#ffebee',
    borderRadius: '8px',
  },
  modalConfirmationSpan: {
    marginBottom: theme.spacing(2),
    marginTop: '0px',
  },
  modalButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  modalActionButton: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(2),
  },
  modalActionButtonDanger: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(2),
    backgroundColor: '#b71c1c',
    color: '#ffffff',
    "&:hover": {
      backgroundColor: '#911616',
    }
  },
  chevronButton: {
    textAlign: "center",       
    fontSize: "0.875rem",
    fontFamily: "Roboto",
    fontWeight: "400",
    color: "rgb(127 127 127)",
    "&:hover": {
        color:"#0b5c96",
    },
    background: "none"
  },
  counters: {
    marginLeft: 15
  },
  counterNews: {
    paddingBottom: 20
  },
  warning: {
    alignItems: 'center',
    marginBottom: 15,
    marginTop: 15
  },
  AlertInformation: {
    color: '#000000',
    backgroundColor: '#e1f1fd',
    alignItems: 'center',
    paddingLeft:'8px',
    marginBottom: theme.spacing(3),
  },
  Alertoutlined: {
    color: '#000000',
    backgroundColor: 'transparent',
    alignItems: 'center',
    padding:17,
    borderRadius:'4px',
    borderColor:'#e1f1fd',
    borderWidth: 1,
    borderStyle: 'solid',
    marginBottom: theme.spacing(3),
  },
  SubTitle: {
    color: '#000000',
    alignItems: 'center',
    fontSize: '20px',
    paddingLeft:'8px'
  },
  paddingTop26:{
    paddingTop : '26px !important'
  },
  hidden:{
    display: 'none'
  },
  collapsibleContent: {
    padding: "16px 16px 16px 72px",
    backgroundColor: "#fafafa",
  },
  AdminRoles: {
    mensaje: {
      color: '#373740',
      padding: '0',
      fontSize: '14',
      fontStyle: 'normal',
      fontFamily: 'Roboto',
      fontWeight: 500,
      lineHeight: '1.43',
      fontStretch: 'normal',
      letterSpacing: '0.25',
    },
    lista: {
        padding: '0',
        margin: '0',
        marginLeft: '16'
    },
    listado : {
        marginLeft: '20px',
        paddingLeft: '5px',
    }
  }
}));

export default homeStyles;
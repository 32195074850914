import axios from "axios";
import config from "../../config";
import { getSessionHandler } from "./auth-service";

export async function http(method, url, data, headers, isPublic = false) {
  try {
    let requestHeaders = {...headers};
    if(!isPublic){
      const authSession = await getSessionHandler();
      const accessToken = authSession.AccessToken;
      requestHeaders = { ...requestHeaders,  "x-cognito-access-token": `${accessToken}` }
    } 

    const { data: response } = await axios({
      method,
      url,
      data,
      headers: requestHeaders,
    });

    return response;
  }
  catch (error) {
    console.error(error);
    throw error;
  }
}

export async function Lambda({ method, resource, data, headers, isPublic }) {
  let resp = await http(method, `${config.API_URL}${resource}`, data, headers, isPublic);
  return resp;
}

import { useCallback } from "react";
import { PasswordInputValidation, UserInputValidation, isEmailValid } from "../../utilities/validations";

/** Hook validador de inputs */
const useInputValidator = () => {
    // eslint-disable-next-line
    const validateInput = useCallback((objectToValidate, applyData) => {
        /** Si el texto no es vacio entonces validara */
        if (objectToValidate.text !== "") {
            const data = objectToValidate.isPassword ? PasswordInputValidation(objectToValidate.text) : UserInputValidation(objectToValidate.text);
            applyData(data);
        }
    });

    /** Retornara el mensaje de validacion */
    return {
        validateInput
    };
};

export const useInputValidatorEmails = () => {
    // eslint-disable-next-line
    const validateInputEmail = useCallback((objectToValidate, applyData) => {
        /** Si el texto no es vacio entonces validara */
        if (objectToValidate.text !== "") {
            const data = isEmailValid(objectToValidate.text);
            applyData(data);
        }
    });

    /** Retornara el mensaje de validacion */
    return {
        validateInputEmail
    };
};

export default useInputValidator;
import React, { useContext } from 'react';
import logo from "../../images/logo-masorden-gris-small.svg";
import { AppBar, Toolbar, GTIWebButton, Typography, Link, MenuOpen, Person } from "@gti-controls/web";
import homeStyles from "../../common/styles/Home";
import { AuthContext } from "../../providers/auth";
import { SettingsContext } from '../../providers/settings';
import AccountSelector from "../selector";

const Header = () => {
  const classes = homeStyles();
  const { state: authContext, dispatch } = useContext(AuthContext);
  const {
    isSelectingAccount,
    handleDrawerToggle,
  } = React.useContext(SettingsContext);

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        {!isSelectingAccount ? (
          <div className={classes.menuButton}>
            <GTIWebButton
              onlyIcon={true}
              onClick={() => handleDrawerToggle()}
              icon={<MenuOpen />} />
          </div>
        ) : null}
        <div className={classes.logo}>
          <img src={logo} alt="Logo" className={classes.imageIcon} />
        </div>
        <AccountSelector/>
        <div className={classes.spacer}/>
        <div className={classes.user}>
          <div className={classes.userIcon}>
            <Person className={classes.userIcon} />
          </div>
          <Typography noWrap className={classes.userTitle}>
            {authContext.user.username}
          </Typography>
        </div>
        <div className={classes.logout}>
          <Link color="inherit" onClick={() => dispatch({
            type: "LOGOUT",
            signOff: true,
          }) }>Salir</Link>
        </div>
      </Toolbar>
    </AppBar>
  )
}
export default Header;
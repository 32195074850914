/** Modulos necesarios del contenedor */
import React, { useContext, useEffect, useState } from "react";
import logo from "../../images/logo-masorden-gris-small.svg";
import loginImage from "../../images/img-login.svg";
import LoginLayout from "../../layouts/login-layout";
import loginStyles from "../../common/styles/Login";
import { GTIWebTextField, GTIWebButton, GTIWebLink, Paper, Grid, Error, CheckCircle, CircularProgress } from "@gti-controls/web";
import useHttpLogin from "../../hooks/useHttpLogin";
import { useHistory, useLocation } from "react-router-dom";
import config from "../../config";
import { AuthContext } from "../../providers/auth";
import useInputValidator from "../../hooks/useInputValidator";
import LoginAlert from "../../components/login-alert";
import { usePageTracking } from "@gti-controls/web";

/** Constante del contenedor */
export const Maintenance = () => {

  /** Hooks de uso */
  const history = useHistory();
  const location = useLocation();
  const { dispatch } = useContext(AuthContext);
  const classes = loginStyles();
  /** Usuario */
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    userState: null,
    enterState: null,
    userLegend: "Entra con el dato que tienes registrado",
    userIcon: null,
    enterLegend: null
  });
  /** Contraseña */
  const { error, isLoading, sendRequest: loginRequest } = useHttpLogin();
  const { validateInput: inputValidator } = useInputValidator();


  useEffect(() => {
    if (error) {
      if (error.error === "11")
        setFormData({ ...formData, userState: "error", userIcon: <Error style={{ color: "#b71c1c" }} /> })
      if (error.error === "2")
        setFormData({ ...formData, userState: "success", enterState: "error" })
    }
    else {
      setFormData({ ...formData, userState: null, userIcon: null, enterState: null })
    }
  }, [error]);

  /** Redirecciona al usuario de acuerdo a las cuentas que tiene disponibles para seleccionar */
  const redirectAfterLogin = (accountsLength) => {
    history.replace(accountsLength === 1 ? { pathname: '/'} : {
      pathname: '/SeleccionarCuenta',
      state: {
        verb: {
          ItemID: -1, 
          VerboClassName: 'SeleccionarCuenta', 
          Texto: 'Seleccionar Cuenta'
        },
      }
    });
  }

  /** Funcion que logea el usuario a la consola */
  const accessConsole = async (e) => {
    e.preventDefault();
    /** Se valida la forma */
    if (validateForm()) {
      /** Constante que almacena la informacion de usuario en sesion
     * en caso de que la respuesta haya sido exitosa
     */
      const handleResponse = (response) => {
        dispatch({
          type: "LOGIN",
          payload: {
            user: {
              username: response.user.name,
              userId: response.user.userId,
              sesionId: response.user.sessionId,
            },
            accountsLength: response.accounts.length,
            firstAccount: (response.accounts.length === 1) ? {
              despachoId: response.accounts[0].DespachoID,
              nombre: response.accounts[0].DespachoNombre,
              contacto: response.accounts[0].DespachoContacto,
              escuelaNombre: response.accounts[0].EscuelaNombre,
              empresas: response.accounts[0].Empresas,
            }  : null,
          },
        });
        redirectAfterLogin(response.accounts.length);
      };

      /** Invocacion del hook sendRequest como loginRequest */
      loginRequest({
        url: config.API_URL,
        username: formData.username,
        password: formData.password,
      }, handleResponse);
    }
  };

  const validateForm = () => {
    if (formData.username === "" || formData.password === "") {
      setFormData({ ...formData, userLegend: "Correo electrónico, Teléfono celular o CURP es un campo obligatorio", userState: "error", enterLegend: "Contraseña es un campo obligatorio", enterState: "error", userIcon: <Error style={{ color: "#b71c1c" }} /> });
      return false;
    }
    if (formData.userState === null || formData.userState)
      return true;
    else
      return false;
  };

  /** Constante que valida el Input de usuario */
  const validateInput = () => {
    const handleValidator = (objectResponse) => {
      if (objectResponse.isValid) {
        setFormData({
          ...formData,
          userLegend: "Entra con el dato que tienes registrado",
          userState: "success",
          userIcon: <CheckCircle style={{ color: "#108043" }} />
        })
      }
      else {
        setFormData({
          ...formData,
          userLegend: objectResponse.text,
          userState: "error",
          userIcon: <Error style={{ color: "#b71c1c" }} />
        })
      }
    };
    inputValidator({ text: formData.username }, handleValidator);
  };

  /** Constante que maneja los cambios en los campos */
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value.trim() });
  };

  /** Codigo de la UI */
  return (
    <LoginLayout image={loginImage} title={"Temporalmente en mantenimiento"}>
      <Grid item component={Paper} className={classes.maintenanceContainer} >
        <div className={classes.logo}>
          <img src={logo} alt="Logo" className={classes.imageIcon} />
        </div>
        <Grid container>
          <div className={classes.title}>
              +ORDEN esta temporalmente en mantenimiento.
           </div>
        </Grid>
        <div className={classes.paper}>
          <form className={classes.form} >
            <Grid container spacing={1}>
        
              
            <Grid container justify="Left" className={classes.maintenance}>
                <span style={{fontSize:'16px'}}>
                  ¡Lo sentimos!
                </span>
              </Grid>
              <Grid container justify="Left" className={classes.maintenanceSub}>
                <span style={{fontSize:'12px'}}>
                Estamos trabajando para mejorar tu experiencia, estaremos de vuelta lo antes posible..
                </span>
              </Grid>
    
            </Grid>
    
          </form>
        </div>
      </Grid>
    </LoginLayout>
  )
};

/** Exportacion del componente para su invocacion en otros archivos */
export default Maintenance;
import { createContext, useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";

export const DataContext = createContext({});
export const DataProvider = props => {

  const location = useLocation();
  const local = JSON.parse(localStorage.getItem('data') || "{}");
  const [data, setData] = useState(local || {});

  /** Hook de prueba para ver los cambios de "data" en consola del explorador */
  useEffect(() => {
    localStorage.setItem("data", JSON.stringify(data));
  }, [data])

  const getContainer = () => {
    return location.pathname.replace('/', '');
  }

  const getFilterData = (name, global=false) => {
    const container = global ? 'AppData' : getContainer();
    const d = data[container];
    const filters = (d && d.filters) ? d.filters : {};
    return (filters && filters[name]) ? filters[name] : '';
  }
  
  /** Handle any container filter changed */
  const handleFilterChange = (name, value, global=false) => {

    const container = global ? 'AppData' : getContainer();

    if (data[container]) {
      setData(prevState => ({
        ...prevState,
        [container]: { 
          ...prevState[container], 
          filters: {
            ...prevState[container].filters,
            [name]: value 
          },
        }
      }));
    }
    else {
      setData(prevState => ({
        ...prevState,
        [container]: {
          filters: {
            [name]: value
          } 
        }
      }));
    }
  };

  const clearData = () => {
    setData({});
  };

  return (
    <DataContext.Provider value={{
      getFilterData,
      handleFilterChange,
      clearData,
    }}>
      {props.children}
    </DataContext.Provider>
  );
};
export const gutterWidths = {
    xs: 16,
    sm: 16,
    md: 24,
    lg: 24,
    xl: 24
  }
  
  export const columnsCount = {
    xs: 4, 
    sm: 8,
    md: 12,
    lg: 12, 
    xl: 12
  }
  
  export const marginWidths = {
    xs: 16,
    sm: 16, 
    md: 24, 
    lg: 28,
    xl: 72
  }
  
  export const columnsMainCount = {
    xs: 4, 
    sm: 6,
    md: 9,
    lg: 9, 
    xl: 9
  }
  
  export const columnsWidth = {
    xs: gutterWidths.xs * 3 + marginWidths.xs * 2,
    sm: gutterWidths.sm * 7 + marginWidths.sm * 2,
    md: gutterWidths.md * 11 + marginWidths.md * 2,
    lg: gutterWidths.lg * 11 + marginWidths.lg * 2,
    xl: gutterWidths.xl * 11 + marginWidths.xl * 2,
  }
  
  export const columnsMainWidth = {
    xs: gutterWidths.xs * 3,
    sm: gutterWidths.sm * 5,
    md: gutterWidths.md * 8,
    lg: gutterWidths.lg * 8,
    xl: gutterWidths.xl * 9,
  }
import { AuthProvider } from "./providers/auth";
import AppRouter from "./router";

function App() {
  return (
    <AuthProvider>
      <AppRouter></AppRouter>
    </AuthProvider>
  );
}

export default App;

import { makeStyles } from "@gti-controls/web";
import { columnsWidth, columnsCount, gutterWidths, marginWidths } from "../../../layouts/constants";

const loginStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    backgroundColor: '#0b5c96',
    overflow: 'auto',
    [theme.breakpoints.down('md')]: {
      overflow: 'unset'
    }
  },
  image: {
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#0b5c96',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    textAlign: 'center',
    [theme.breakpoints.only('md')]: {
      marginLeft: `calc((((100% - ${columnsWidth.md}px) *2) / ${columnsCount.md}) + ${gutterWidths.md * 2 + marginWidths.md * 1}px)`,
      marginRight: `calc((((100% - ${columnsWidth.md}px)) / ${columnsCount.md}) + ${gutterWidths.md}px)`,
    },
    [theme.breakpoints.only('lg')]: {
      marginBottom: '10%',
      marginLeft: `calc((((100% - ${columnsWidth.lg}px) * 2) / ${columnsCount.lg}) + ${gutterWidths.lg + marginWidths.lg * 2}px)`,
      marginRight: `calc((((100% - ${columnsWidth.lg}px)) / ${columnsCount.lg}) + ${gutterWidths.lg + marginWidths.lg}px)`
    }
  },
  imageContainer: {
    marginTop: 'auto',
    marginBottom: theme.spacing(13),
    [theme.breakpoints.only('md')]: {
      paddingLeft: marginWidths.md,

      width: `calc((((100% - ${columnsWidth.md}px) * 6) / ${columnsCount.md}) + ${gutterWidths.md * 5 + marginWidths.md * 1}px)`,
    },
    [theme.breakpoints.only('lg')]: {
      paddingLeft: marginWidths.lg,
      width: `calc((((100% - ${columnsWidth.lg}px) * 6) / ${columnsCount.lg}) + ${gutterWidths.lg * 5 + marginWidths.lg * 1}px)`
    },
    [theme.breakpoints.only('xl')]: {
      paddingLeft: marginWidths.lg,
      width: `calc((((100% - ${columnsWidth.lg}px) * 6) / ${columnsCount.lg}) + ${gutterWidths.lg * 5 + marginWidths.lg * 1}px)`
    },
  },
  imageTextContainer: {
    color: "white",
    fontFamily: "Roboto",
    textAlign: 'center',
    marginBottom: theme.spacing(5),
    [theme.breakpoints.only('md')]: {
      marginLeft: `calc((((100% - ${columnsWidth.md}px) *2) / ${columnsCount.md}) + ${gutterWidths.md * 2 + marginWidths.md * 1}px)`,
      marginRight: `calc((((100% - ${columnsWidth.md}px)) / ${columnsCount.md}) + ${gutterWidths.md}px)`,
    },
    [theme.breakpoints.only('lg')]: {
      marginLeft: `calc((((100% - ${columnsWidth.lg}px) * 2) / ${columnsCount.lg}) + ${gutterWidths.lg + marginWidths.lg * 2}px)`,
      marginRight: `calc((((100% - ${columnsWidth.lg}px)) / ${columnsCount.lg}) + ${gutterWidths.lg + marginWidths.lg}px)`
    },
    [theme.breakpoints.only('xl')]: {
      marginLeft: `calc((((100% - ${columnsWidth.lg}px) * 2) / ${columnsCount.lg}) + ${gutterWidths.lg + marginWidths.lg * 2}px)`,
      marginRight: `calc((((100% - ${columnsWidth.lg}px)) / ${columnsCount.lg}) + ${gutterWidths.lg + marginWidths.lg}px)`
    }
  },
  imageText: {
    fontWeight: 500,
    letterSpacing: '0.15px',
    fontSize: 20,
    lineHeight: '30px'
  },
  imageSize: {
    height: 160
  },
  logo: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  title: {
    [theme.breakpoints.only('xs')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.only('sm')]: {
      marginRight: theme.spacing(4),
    },
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(6)
    },
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.5,
    letterSpacing: "normal",
    marginBottom: theme.spacing(1),
    display: 'inline',
  },
  imageIcon: {
    height: '48px',
    width: '124px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    marginBottom: theme.spacing(3)
  },
  input: {
    marginBottom: theme.spacing(0)
  },
  forgotPassword: {
    marginBottom: theme.spacing(5)
  },
  signUp: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4)
  },
  signUpText: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  signUpAlterative: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(5)
  },
  maintenance: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1)
  },
  maintenanceSub: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(8)
  },
  help: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      marginBottom: '16px'
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '16px'
    }
  },
  divider: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginBottom: '16px'
    }
  },
  loginContainer: {
    [theme.breakpoints.only('xs')]: {
      paddingLeft: marginWidths.xs,
      paddingRight: marginWidths.xs,
      width: '100%',
      borderRadius: '0px !important',
    },
    [theme.breakpoints.only('sm')]: {
      paddingLeft: marginWidths.sm,
      paddingRight: marginWidths.sm,
      width: '100%',
      borderRadius: '0px !important',
    },
    [theme.breakpoints.only('md')]: {
      paddingLeft: marginWidths.md,
      paddingRight: marginWidths.md,
      marginTop: 'auto',
      marginBottom: theme.spacing(2),
      width: `calc((((100% - ${columnsWidth.md}px) *6) / ${columnsCount.md}) + ${gutterWidths.md * 5 + marginWidths.md * 0}px)`
    },
    [theme.breakpoints.only('lg')]: {
      paddingLeft: marginWidths.lg,
      paddingRight: marginWidths.lg,
      marginTop: 'auto',
      //height: '570px',
      marginBottom: theme.spacing(2),
      width: `calc((((100% - ${columnsWidth.lg}px) * 4) / ${columnsCount.lg}) + ${gutterWidths.lg * 5 + marginWidths.lg * 0}px)`
    },
    [theme.breakpoints.up('xl')]: {
      paddingLeft: marginWidths.xl,
      paddingRight: marginWidths.xl,
      marginTop: 'auto',
      marginBottom: theme.spacing(2),
      width: `calc((((100% - ${columnsWidth.xl}px) * 4) / ${columnsCount.xl}) + ${gutterWidths.xl * 5 + marginWidths.xl * 0}px)`
    },
  },
  forgotContainer: {
    [theme.breakpoints.only('xs')]: {
      paddingLeft: marginWidths.xs,
      paddingRight: marginWidths.xs,
      width: '100%',
      borderRadius: '0px !important',
    },
    [theme.breakpoints.only('sm')]: {
      paddingLeft: marginWidths.sm,
      paddingRight: marginWidths.sm,
      width: '100%',
      borderRadius: '0px !important',
    },
    [theme.breakpoints.only('md')]: {
      paddingLeft: marginWidths.md,
      paddingRight: marginWidths.md,
      marginTop: 'auto',
      marginBottom: theme.spacing(2),
      width: `calc((((100% - ${columnsWidth.md}px) *6) / ${columnsCount.md}) + ${gutterWidths.md * 5 + marginWidths.md * 0}px)`
    },
    [theme.breakpoints.only('lg')]: {
      paddingLeft: marginWidths.lg,
      paddingRight: marginWidths.lg,
      marginTop: 'auto',
      //height: '570px',
      marginBottom: theme.spacing(2),
      width: `calc((((100% - ${columnsWidth.lg}px) * 4) / ${columnsCount.lg}) + ${gutterWidths.lg * 5 + marginWidths.lg * 0}px)`
    },
    [theme.breakpoints.up('xl')]: {
      paddingLeft: marginWidths.xl,
      paddingRight: marginWidths.xl,
      marginTop: 'auto',
      marginBottom: theme.spacing(2),
      width: `calc((((100% - ${columnsWidth.xl}px) * 4) / ${columnsCount.xl}) + ${gutterWidths.xl * 5 + marginWidths.xl * 0}px)`
    },
  },
  maintenanceContainer: {
    [theme.breakpoints.only('xs')]: {
      paddingLeft: marginWidths.xs,
      paddingRight: marginWidths.xs,
      width: '100%',
      borderRadius: '0px !important',
    },
    [theme.breakpoints.only('sm')]: {
      paddingLeft: marginWidths.sm,
      paddingRight: marginWidths.sm,
      width: '100%',
      borderRadius: '0px !important',
    },
    [theme.breakpoints.only('md')]: {
      paddingLeft: marginWidths.md,
      paddingRight: marginWidths.md,
      marginTop: 'auto',
      marginBottom: theme.spacing(14),
      width: `calc((((100% - ${columnsWidth.md}px) *6) / ${columnsCount.md}) + ${gutterWidths.md * 5 + marginWidths.md * 0}px)`
    },
    [theme.breakpoints.only('lg')]: {
      paddingLeft: marginWidths.lg,
      paddingRight: marginWidths.lg,
      marginTop: 'auto',
      //height: '570px',
      marginBottom: theme.spacing(14),
      width: `calc((((100% - ${columnsWidth.lg}px) * 4) / ${columnsCount.lg}) + ${gutterWidths.lg * 5 + marginWidths.lg * 0}px)`
    },
    [theme.breakpoints.up('xl')]: {
      paddingLeft: marginWidths.xl,
      paddingRight: marginWidths.xl,
      marginTop: 'auto',
      marginBottom: theme.spacing(14),
      width: `calc((((100% - ${columnsWidth.xl}px) * 4) / ${columnsCount.xl}) + ${gutterWidths.xl * 5 + marginWidths.xl * 0}px)`
    },
  },
  columnSpace: {
    marginRight: marginWidths.lg,
    width: `calc((((100% - ${columnsWidth.lg}px) * 2) / ${columnsCount.lg}) + ${gutterWidths.lg * 1 + marginWidths.lg * 0}px)`
  },
  links: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    color: '#1878bd'
  },
  [theme.breakpoints.down('sm')]: {
    footer: {
      position: 'absolute',
      bottom: 0,
      width: 'inherit',
      right: 0
    }
  },
  footer: {
    width: '100%',
    fontSize: '16px',
    lineHeight: '24px',
    marginTop: 'auto',
    color: '#ffffff',
    padding: 10,
    [theme.breakpoints.down('sm')]: {
      color: '#0b5c96',
      fontSize: '11px',
    }
  },
  footerGrid: {
    marginTop: theme.spacing(1),
  },
  footerTitle: {
    fontWeight: 500,
  },
  footerSubtitle: {
    flexGrow: 1
  },
  footerLinks: {
    cursor: 'pointer'
  },
  dividerLine: {
    width: '100%',
    textAlign: 'center', 
    borderBottom: '1px solid #9696AD', 
    lineHeight: '0.1em',
    margin: '10px 0 20px'
  },
  text : {
    background: '#fff',
    padding: '0 10px',
    fontSize: '16px',
    fontFamily: 'Roboto',
    fontWeight: 400,
    color : '#3D3D44'
  },
  backSpace: {
    marginRight: theme.spacing(2),
    display: 'inline',
  },
  containerTitle: {
    display: 'flex',
    flex: 'wrap',
    boxSizing: 'border-box'
  },
  buttonSpace: {
    paddingTop: 25,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(10)
  }
}));

export default loginStyles;
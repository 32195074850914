import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { Typography, GTIWebButton, ArrowBack } from "@gti-controls/web";
import homeStyles from "../../common/styles/Home";
import { SettingsContext } from '../../providers/settings';

const Title = () => {
	const location = useLocation();
	const history = useHistory();

	const classes = homeStyles();
	const { settings, actionSelected } = useContext(SettingsContext);
	const [returnVisible, setReturnVisible] = useState(false);
	const [subOption, setSubOption] = useState({});

	useEffect(() => {
		const pathArray = location.pathname.split("/");
		const retVisible = (!actionSelected && pathArray.length > 2 && location?.state) || (pathArray.length > 2 && location.search);

		setReturnVisible(retVisible);
		setSubOption((retVisible && location.state?.sub) ? location.state.sub : {});

		// eslint-disable-next-line
	}, [location.pathname, actionSelected]);

	const handleBackButtonClick = () => {
		history.goBack();
	};

	return (
		<div>
			<Typography variant="h5" className={classes.pageTitle}>
				{returnVisible &&
					<div className={classes.backSpace}>
						<GTIWebButton
							icon={<ArrowBack className={classes.backButton} />}
							iconPosition="start"
							onlyIcon={true}
							onClick={handleBackButtonClick}
						/>
					</div>
				}
				{(actionSelected) ?
					(actionSelected.texto || '') :
					(`${settings.menuOptionSelected?.Texto || ''}${(subOption.texto || '') ? `: ${subOption.texto || ''}` : ''}` || '')}
			</Typography>
		</div>
	);
};

export default Title;
import { useCallback, useState } from "react";
import { login } from "../../common/services/auth-service";
import { logout } from '../../common/services/auth-service';
import { confirmUsuarioAdmin, recoveryUsuarioAdmin, confimrRecoveryUsuarioAdmin } from "../../common/services/auth-service";
import { http } from "../../common/services/http-service";
import config from "../../config";

/** Hook para realizar requests a un API mediante http/https */
const useHttpLogin = () => {
    /** Hook que devuelve valores en caso de carga o error */
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    /** Funcion principal del Hook */
    const sendRequest = useCallback(async (loginConfig, applyData) => {
        /** Se setea el valor inicial de los valores de carga y error */
        setIsLoading(true);
        setError(null);
        try {
            /** Administrador de respuesta */
            const response = await login(loginConfig.username, loginConfig.password);
            applyData(response);
            
        } catch (error) {
            let errorDesc = error?.response?.data?.msg;
            let errHttpLogin = error;
            if (errorDesc) {
                errHttpLogin = {
                    error: "4",
                    error_description: errorDesc
                }
            }
            logout();
            setError(errHttpLogin);
        }
        setIsLoading(false);
    }, []);
     

    const validateSSO = async (username) => {
        try {
           
            setIsLoading(true);
            setError(null);
            let data = {
                "username":username,
                "url":`${window.location.origin}${window.location.pathname}`
            }
            const response =  await http('post', `${config.API_URL}auth/validatesso` , data);
            setIsLoading(false);
            
            return response
        } catch (err) {
            setIsLoading(false);
            let httpError = {
                error: "4",
                error_description: err?.response?.data?.msg ? err.response.data.msg  : "Error al validad correo"
            }
            setError(httpError);
            return err.msg;
        }
    };

    const codeSSO = async (code) => {
        try {

            setIsLoading(true);
            setError(null);

            let data = {
                "code":code,
                "url":`${window.location.origin}${window.location.pathname}`
            }
            const response =  await http('post', `${config.API_URL}auth/tokenscode` , data);
            setIsLoading(false);
            
            return response
        } catch (err) {
            setIsLoading(false);
            let httpError = {
                error: "4",
                error_description: err?.response?.data?.msg ? err.response.data.msg  : "Error al validar correo"
            }
            setError(httpError);
            return err.msg;
        }
    };

    const loginSSO = async (tokens) => {
        try {
           
            setIsLoading(true);
            setError(null);
            localStorage.clear();
            let data = {
                AuthenticationResult: tokens
            };
            let response =  await http('post', `${config.API_URL}auth/adminsso` , data);
            response.cognito.AuthenticationResult.Type = 'SSO';

            localStorage.setItem('authTokens', JSON.stringify(response.cognito.AuthenticationResult));
            localStorage.setItem('authInfo', JSON.stringify(response.cognito));

            setIsLoading(false);
            
            return response
        } catch (err) {
            setIsLoading(false);
            let httpError = {
                error: "4",
                error_description: err?.response?.data?.msg ? err.response.data.msg  : "Error al iniciar sesion"
            }
            setError(httpError);
            return err.msg;
        }
    };

    const confirmUser = async (username, password, code) => {
        try {
            setIsLoading(true);
            setError(null);

            let response = await confirmUsuarioAdmin(username, password, code);
            setIsLoading(false);
            return response;

        } catch (error) {
            setIsLoading(false);
            setError(error);
        }
    };

    const forgotPass = async (username) => {
        try {
            setIsLoading(true);
            setError(null);

            let response = await recoveryUsuarioAdmin(username);
            setIsLoading(false);

            return response;

        } catch (error) {
            setIsLoading(false);
            return error;
            
        }
    };

    const confimrPass = async (username, password, code) => {
        try {
            setIsLoading(true);
            setError(null);

            let response = await confimrRecoveryUsuarioAdmin(username, password, code);
            setIsLoading(false);
            return response;
        } catch (error) {
            setIsLoading(false);
            setError(error);
        }
    };

    /** Se retorna los estados y funciones del Hook */
    return {
        setError,
        isLoading,
        error,
        sendRequest,
        validateSSO,
        loginSSO,
        codeSSO,
        confirmUser,
        forgotPass,
        confimrPass
    };
};

export default useHttpLogin;
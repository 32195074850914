import { useContext } from 'react';
import { AuthContext } from "../providers/auth";
import { Router, Switch, Route, Redirect, useLocation } from "react-router-dom";
import { GTIThemeProvider } from "@gti-controls/web";
import history from '../history';
import Login from "../containers/Login";
import ForgotPassword from '../containers/ForgotPassword';
import Maintenance from "../containers/Maintenance";
import AppSwitch from './Switch';
import {  GTIThemeProvider as GTIThemeProvider2 } from "@gti-controls-v2/web-core";
import silverTheme from "../theme";

function ThemeWrapper(props) {
  const location = useLocation();
  return (
  <GTIThemeProvider 
    theme={"masorden"}
    location={location}
  >
    {props.children}
  </GTIThemeProvider>); 
}

function ThemeWrapper2(props) {
  //const location = useLocation();
  return (
    <GTIThemeProvider2 
      theme={silverTheme}
      locale={'es-MX'}
      //location={location}
    >
      {props.children}
    </GTIThemeProvider2>
  );
}

function AppRouter() {
  const auth = useContext(AuthContext);
  return (
    <Router basename={"/"} history={history}>
      <ThemeWrapper2>
        <ThemeWrapper>
          {!auth.state.isAuthenticated ? (
            <Switch>
              <Route path={"/Login"} name="Iniciar Sesión" exact component={Login} />
              <Route path={"/Recuperacion"} name="Recuperación de Contraseña" exact component={ForgotPassword} />
              <Route path={"/maintenance"} name="Mantenimiento" exact component={Maintenance} />
              <Redirect to={{ pathname: '/Login', state: { verb: { Texto: 'Iniciar Sesión' } } }}/>
            </Switch>
          ) : (
            <AppSwitch/>
          )}
        </ThemeWrapper>
      </ThemeWrapper2>
    </Router>
  );
}

export default AppRouter;

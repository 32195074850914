import { Lambda } from "../http-service";

export const cognitoLogin = async(username, password) => { 
    const body = {
        username: username,
        password: password,
        type: 3
    };

    return await Lambda({ resource: `auth/admin`, method: "POST" , isPublic: true, data: JSON.stringify(body) });
};

export const cognitoRefresh = async (refresh_token, access_token, type = 3) => {
    const body = {
      refresh_token,
      type: type,
      access_token
    };
  
    return await Lambda({ resource: `auth/refresh`, method: "POST", isPublic: true, data: JSON.stringify(body) });
};

export const cognitoLogOut = async (access_token) => {
    const body = {
      access_token,
      type: 3
    };
  
    return await Lambda({ resource: `auth/logout`, method: "POST", isPublic: true, data: JSON.stringify(body) });
};

export const cognitoAdminConfimar = async (username, password, code) => {
  const body = {
    username: username,
    password: password,
    code: code
  };

  return await Lambda({ resource: 'cognito-admin/confirma', method: "POST", isPublic: true, data: JSON.stringify(body) });
};

export const cognitoAdminRecovery = async (username) => {
  const body = {
    username: username
  };

  return await Lambda({ resource: 'cognito-admin/recupera', method: "POST", isPublic: true, data: JSON.stringify(body) });
};

export const cognitoAdminConfirmRecovery = async (username, password, code) => {
  const body = {
    username: username,
    password: password,
    code: code
  };

  return await Lambda({ resource: 'cognito-admin/recupera', method: "PUT", isPublic: true, data: JSON.stringify(body) });
};
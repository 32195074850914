import { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import config from "../../config";
import useHttp from '../../hooks/useHttp';
import { AuthContext } from "../auth";
import { DataContext } from "../data";

export const SettingsContext = createContext({});

const initialState = {
  mobileDrawerOpened: false,
  firstOption: {},
  menuOptionSelected: {},
  lastOptionSelected: {},
  accountSelected: {},
};

const accountSelectionItem = {
  ItemID: -1, 
  VerboClassName: 'SeleccionarCuenta', 
  Texto: 'Seleccionar Cuenta'
}

export const SettingsProvider = props => {
  const { state: authContext } = useContext(AuthContext);
  const { clearData } = useContext(DataContext);
  const {sendRequest :fetchSetAccount} = useHttp();
  const {sendRequest :fetchLogVerbs} = useHttp();
  const local = JSON.parse(localStorage.getItem('settings'));
  const [settings, setSettings] = useState(local || initialState);
  const [isSelectingAccount, setIsSelectingAccount] = useState(localStorage.getItem('isSelectingAccount') === null);
  const [isLoadingContainer, setIsLoadingContainer] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [actionSelected, setActionSelected] = useState(null);
  const [locationKeys, setLocationKeys] = useState([]);
  const [titleData, setTitleData] = useState(null);
  const history = useHistory();

  const checkIsSelection = (location) => {
    const isSelectingAccountPath = location.pathname === '/SeleccionarCuenta';
    setIsSelectingAccount(isSelectingAccountPath);
    localStorage.setItem("isSelectingAccount", isSelectingAccountPath);
    if (isSelectingAccountPath && settings.accountSelected.despachoId) {
      setContext({ menuOptionSelected: accountSelectionItem });
    }
  }

  const setMenuOptionFromURL = (location) => {
    const urlOption = menuItems.find(option => option.VerboClassName.replace('#', '') === location.pathname.split("/")[1] || null);
    if (urlOption) {
      setContext({ 
        menuOptionSelected: urlOption,
        lastOptionSelected: urlOption,
      });
    }
    setActionSelected((location.state && location.state.action) ? location.state.action : null);
  }

  /** Hook useEffect que se intercepta cambios en history del explorador */
  useEffect(() => {
    return history.listen(location => {
      checkIsSelection(location);
      /** Asignación de estados necesarios para eventos 'back' y 'forward' del browser */
      if (history.action === 'PUSH') {
        setLocationKeys([ location.key ])
      } else if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([ _, ...keys ]) => keys);
          // Handle forward event
          setMenuOptionFromURL(location);
  
        } else {
          setLocationKeys((keys) => [ location.key, ...keys ]);
          // Handle back event
          setMenuOptionFromURL(location);
        }
      }
    })
    // eslint-disable-next-line
  }, [locationKeys])

  /** Set settings state */
  const setContext = useCallback(
    updates => {
      const updatedSettings = { ...settings, ...updates };
      setSettings(updatedSettings);
      localStorage.setItem("settings", JSON.stringify(updatedSettings));
    },
    [settings, setSettings],
  )

  /** Handle menu icon click event in mobile devices */
  const handleDrawerToggle = () => {
    setContext({mobileDrawerOpened: !settings.mobileDrawerOpened});
  };

  const handleMenuItemsChange = (data) => {
    const mItems = [...data];
    if (mItems && Array.isArray(mItems)) { mItems.push(accountSelectionItem); }
    setMenuItems(mItems);
  };

  /** Handle account change in account selector */
  const handleAccountChange = async (item) => {
    setIsLoadingContainer(true);
    await setAccount(item); //Bitácora
    clearData(); //Limpia los filtros
    const redirectOption = (Number(settings.lastOptionSelected.ItemID) > 0) ? settings.lastOptionSelected : settings.firstOption;
    setContext({
      menuOptionSelected: redirectOption,
      accountSelected: item,
    });
    setActionSelected(null);
    setIsLoadingContainer(false);
    redirect(redirectOption);
  };

  /** Handle option change in sidebar */
  const handleOptionChange = (item, mobileDrawerOpened, firstOption, keep, action) => {
    setLogVerbs(item); //Bitácora
    item = !settings.accountSelected.despachoId && authContext.accountsLength > 1 ? accountSelectionItem : item;
    setContext({
      mobileDrawerOpened: (mobileDrawerOpened) ? !mobileDrawerOpened : settings.mobileDrawerOpened,
      firstOption: (firstOption) ? firstOption : settings.firstOption,
      menuOptionSelected: item,
      lastOptionSelected: (item.VerboID) ? item: settings.lastOptionSelected,
      accountSelected: (!settings.accountSelected.despachoId && authContext.accountsLength === 1) ? authContext.firstAccount : settings.accountSelected,
    });
    setActionSelected(action || null);
    if (!keep) { redirect(item); }
  };

  const redirect = (item) => {
    history.push({
      pathname: `/${(item.VerboClassName || 'consola').replace('#', '')}`,
      state: { 
        verb: item,
      }
    });
  }

  /** Call service to notify account change */
  const setAccount = async (account) => {
    if (account && account.despachoId) {
     await fetchSetAccount({
        url: config.ADMIN_API_URL + `sesiones/despacho`,
        method: "POST",
        body: {
          despachoId: account.despachoId,
        },
      }, () => {});
    }
  }

  /** Call service to notify menu option change */
  const setLogVerbs = (verb) => {
    if (verb && (verb.VerboID || verb.verbo.id)) {
      fetchLogVerbs({
        url: config.API_URL + `logs/verbs`,
        method: "POST",
        body: {
          userId: authContext.user.userId,
          sessionId: authContext.user.sesionId,
          verbId: verb.VerboID || verb.verbo.id
        },
      }, () => {});
    }
  }

  /** Handle action selected in datagrid */
  const handleActionSelected = (item) => {
    setLogVerbs(item); //Bitácora
    setActionSelected(item);
  };

  /** Handle loading container changed */
  const handleLoadingContainerChange = (value) => {
    setTimeout(() => {
      setIsLoadingContainer(value);
    }, (!value) ? 700 : 0);
  };

  const handleTitleChange = (titleData) => {
    setTitleData(titleData);
  };

  return (
    <SettingsContext.Provider value={{
      settings,
      isSelectingAccount,
      isLoadingContainer,
      actionSelected,
      titleData,
      handleDrawerToggle,
      handleAccountChange,
      handleMenuItemsChange,
      handleOptionChange,
      handleLoadingContainerChange,
      handleActionSelected,
      handleTitleChange,
    }}>
      {props.children}
    </SettingsContext.Provider>
  );
};
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { SettingsContext } from '../../providers/settings';
import useFormat from "../../hooks/useFormat";
import GridView from '../../components/gridview';
import moment from "moment";

export const Accounts = () => {
  const { emptyNumberFormatter } = useFormat();
  const { handleAccountChange } = React.useContext(SettingsContext);
  const location = useLocation();
  const [isSelection, setIsSelection] = useState(false);

  useEffect(() => {
    setIsSelection(location.pathname !== '/Cuentas');
  }, []);

  const handleCellClick = (record) => {
    if (isSelection) {
      handleAccountChange(record.row);
    }
  }

  /** Columnas del grid */
  const cols = [
    { field: 'despachoId', headerName: 'ID', type: 'number', width: 100, valueFormatter: emptyNumberFormatter },
    { field: 'nombre', headerName: 'Nombre', flex: 1 },
    { field: 'escuelaNombre', headerName: 'Oficina', width: 300 },
    { field: 'empresas', headerName: 'Empresas', type: 'number', width: 150, valueFormatter: emptyNumberFormatter },
  ];

  /** UI */
  return (
    <GridView
      url={'despachos/'}
      newApi={true}
      columns={cols}
      getRowId={(r) => r.despachoId}
      firstRowSelectedOnLoad={!isSelection}
      showCrudButtons={!isSelection}
      showMoreActionsSelector={!isSelection}
      showBasicActionButtons={!isSelection}
      showSearchTextField={true}
      searchTextFieldAutoFocus={isSelection}
      onCellClick={handleCellClick}
      exportedFileName={`Cuentas-${moment(new Date()).format("DD-MM-YYYY")}`}
    />
  )
};

export default Accounts;
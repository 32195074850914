import React, { useEffect, useState } from "react";
import { GTIWebAlert } from "@gti-controls/web";

/**Componente que maneja las alertas de Login */
export const LoginAlert = ({
    severity,
    error
}) => {
    /** Se setea el tipo de error al cargar el componente */
    useEffect(() => { if (error) setErrorType(error.error); }, [error]);

    /** Setea el tipo de error (ambiente/aplicacion) */
    const setErrorType = (errorType) => {
        if (errorType === null || errorType === undefined)
            setEnvError(true);
        else
            setAppError(true);
    };

    /** Un error de ambiente es cuando la consola no puede conectarse a su API (404) */
    const [envError, setEnvError] = useState(false);
    /** Un error de aplicación se presenta cuando el API respondió con un error distinto
     * al input del usuario (Cuenta bloqueada)
      */
    const [appError, setAppError] = useState(false);

    return (
        envError ?
            <GTIWebAlert
                severity={error?.data ? "error" : "warning"}
                alertText={error?.data ? error.data?.errorDescription : "Se produjo un error. Parece que +ORDEN está tardando mucho en responder, inténtalo más tarde."} />
            : appError ?
                <GTIWebAlert severity={severity} alertText={error.error_description} /> : null
    );
};

export default LoginAlert;
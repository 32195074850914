import React from 'react';
import { Typography, Grid, CssBaseline, Hidden } from '@gti-controls/web';
import Footer from '../../components/footer';
import loginStyles from '../../common/styles/Login';

export default function LoginLayout(props) {
  const classes = loginStyles();
  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      {
        props.image ? (
          <Hidden smDown implementation="js">
            <Grid item className={classes.imageContainer} >
              <div className={classes.image}>
                <img src={props.image} alt="login" className={classes.imageSize} />
              </div>
              <div className={classes.imageTextContainer}>
                <Typography className={classes.imageText}>
                  {props.title}
                </Typography>
              </div>
            </Grid>        
          </Hidden>
        ) : null 
      }
      {props.children}
      <Footer />
    </Grid>
  );
}
import React from 'react';
import { Grid, Link } from "@gti-controls/web";
import loginStyles from '../../common/styles/Login';

const Footer = () => {
  const classes = loginStyles();

  return (
    <div className={classes.footer}>
      <Grid container className={classes.footerGrid}>
        <Grid item className={classes.footerTitle}>
          Servicios de Tecnología Contable S.C.
        </Grid>
        <Grid item className={classes.footerSubtitle}>
          &nbsp;| Derechos reservados &copy; {new Date().getFullYear()}
        </Grid>
        <Grid item className={classes.footerLinks}>
          <Grid container>
            <Grid item>
              <Link style={{ textDecoration: 'underline' }} color="inherit" onClick={() => { }}>Términos de uso</Link>
            </Grid>
            <Grid item>
              <div>&nbsp;&nbsp;|&nbsp;&nbsp;</div>
            </Grid>
            <Grid item>
              <Link style={{ textDecoration: 'underline' }} color="inherit" onClick={() => { }}>Política de privacidad</Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
export default Footer;
import React from 'react';
import { CssBaseline } from '@gti-controls/web';
import Header from './../../components/header';
import Sidebar from './../../components/sidebar';
import Backdrop from './../../components/backdrop';
import Title from './../../components/title';
import homeStyles from "../../common/styles/Home";

export default function HomeLayout(props) {
  const classes = homeStyles();

  return (
    <div className={classes.root}>
        <CssBaseline />
        <Header />
        <Sidebar />
        <main className={classes.main}>
          <Backdrop />
          <div className={classes.toolbar} />
          <Title />
          <div>
            {props.children}
          </div>
        </main>
    </div>
  );
}
import { cognitoLogin, cognitoLogOut, cognitoRefresh, cognitoAdminConfimar, cognitoAdminRecovery, cognitoAdminConfirmRecovery } from "./cognito";

export const login = async (uname, password, clientMetadata) => {
	try {
		localStorage.clear();

		let response = await cognitoLogin(uname, password);

		let date = new Date();
		response.cognito.AuthenticationResult.ExpTime = new Date(
			date.getTime() + response.cognito.AuthenticationResult.ExpiresIn * 1000
		).toISOString();
		
		localStorage.setItem(
			"authTokens",
    		JSON.stringify(response.cognito.AuthenticationResult)
		);

		localStorage.setItem("authInfo", JSON.stringify(response.cognito));

		return {
    		accessToken: response.cognito.AuthenticationResult.AccessToken, //tokens.accessToken.jwtToken,
      		user: {
        		...response.user,
        		...response.cognito.attributes,
        		username: response.user.username,
        		sessionId: response.cognito.attributes.sessionId,
      		},
      		companies: response.companies,
      		accounts: response.accounts,
      		employee: response.employee,
      		lastPayroll: response.lastPayroll,
      		cognito: response.cognito,
    	};
	} catch (err) {
    	console.error(err);
    	throw err;
  	}
};

export const logout = async () => {
	try {
		let tokens = getTokens();
		if (tokens) {
			await cognitoLogOut(tokens.AccessToken);
		}
	} catch (error) {
		console.error(error);
	} finally {
		localStorage.clear();
	}
};

export const getSession = async () => {
	let tokens = getTokens();

	if (tokens) {
		if (new Date().toISOString() >= tokens.ExpTime) {
			let response = await cognitoRefresh(
				tokens.RefreshToken,
				tokens.AccessToken,
    			tokens.Type === "SSO" ? 4 : 3
    		);
    		tokens.AccessToken = response.AuthenticationResult?.AccessToken;
      		tokens.IdToken = response.AuthenticationResult?.IdToken;

    		let date = new Date();
    		date.setSeconds(
        		date.getSeconds() + response.AuthenticationResult.ExpiresIn
      		);
      		tokens.ExpTime = date.toISOString();

      		localStorage.setItem("authTokens", JSON.stringify(tokens));
      		localStorage.setItem("authInfo", JSON.stringify(response));
    	}

    	return tokens;
  	}

  	return {};
};

//Manages logout when session couldnt be retrieved
export const getSessionHandler = async () => {
	try {
		const sesion = await getSession();
		if (!sesion) {
    		await logout();
    	}

    	return sesion;
	} catch (error) {
		await logout();
		return null;
	}
};

const getTokens = () => {
	let authTokens = localStorage.getItem("authTokens");

	if (authTokens) {
		let resp = JSON.parse(authTokens);
		return resp;
	}

	return null;
};

export const confirmUsuarioAdmin = async (username, password, code) => {
	try {
		let response = await cognitoAdminConfimar(username, password, code);

		return response;
	} catch (error) {
		throw error.response.data;
	}
};

export const recoveryUsuarioAdmin = async (username) => {
	try {
		let response = await cognitoAdminRecovery(username);

		return response;
	} catch (error) {
		throw error.response.data;
	}
};

export const confimrRecoveryUsuarioAdmin = async (username, password, code) => {
	try {
		let response = await cognitoAdminConfirmRecovery(username, password, code);

		return response;
	} catch (error) {
		throw error.response.data;
	}
};
import React, { useContext, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Typography, Link } from "@gti-controls/web";
import homeStyles from "../../common/styles/Home";
import { AuthContext } from "../../providers/auth";
import { SettingsContext } from '../../providers/settings';

const AccountSelector = () => {
  const classes = homeStyles();
  const { state: authContext } = useContext(AuthContext);
  const { settings, isSelectingAccount, handleActionSelected } = React.useContext(SettingsContext);
  const history = useHistory();

  useEffect(() => {
    if (!isSelectingAccount && !settings.accountSelected.despachoId) {
      redirectAccountSelector();
    }
  }, []);

  const redirectAccountSelector = () => {
    if (authContext.accountsLength > 1) {
      handleActionSelected(null);
      history.push({
        pathname: '/SeleccionarCuenta',
        state: {
          verb: {
            ItemID: -1, 
            VerboClassName: 'SeleccionarCuenta', 
            Texto: 'Seleccionar Cuenta'
          },
        }
      });
    }
  };

  return (
    <div>
      {!isSelectingAccount ? (
        <div className={classes.accountSelector}>     
          <Typography noWrap className={classes.accountTitle}>
            Cuenta:
          </Typography>
          {authContext.accountsLength > 1 ?
          <Link className={classes.accountLink} onClick={redirectAccountSelector}>
            {settings.accountSelected.nombre || 'Sin cuenta seleccionada'}
          </Link>
          : 
          <>{settings.accountSelected.nombre || 'Sin cuenta seleccionada'}</>}
        </div>
      ) : null}
    </div>
  )
}
export default AccountSelector;
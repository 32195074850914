/** Coleccion de validaciones utilizadas en la aplicacion */
const response = {
    text: "",
    isValid: null
};

/** Constante que valida el formato de la expresion regular, retorna true o false dependiendo el caso */
const validateValueFormat = function (value, regExp) {
    if (value.match(regExp))
        return true;
    else
        return false;
}

/** Valida si es un correo electronico */
export function isEmailValid(value) {
    // eslint-disable-next-line
    const regExp = /^[A-z,0-9,\.-]+@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return validateValueFormat(value, regExp);
};

/** Valida si es un CURP */
export function isCurpValid(value) {
    const newValue = value.split('-').join('')
    const regExp = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
    return validateValueFormat(newValue, regExp);
};

/** Valida si es un RFC */
export function isRfcValid(value) {
    const newValue = value.split('-').join('');
    const regExp = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[A-Z0-9]{3})$/;
    return validateValueFormat(newValue, regExp);
};

/** Valida si es un numero telefonico */
export function isPhoneValid(value) {
    // eslint-disable-next-line
    const regExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/im;
    return validateValueFormat(value, regExp);
};

/** Valida si es un numero */
export function isNumberValid(value) {
    const newValue = value.split('.').join('')
        .split('(').join('')
        .split(')').join('')
        .split('-').join('')
        .split(' ').join('');

    const regExp = /^[0-9]+$/;
    return validateValueFormat(newValue, regExp);
}

/** Funcion principal del validador el cual llama a las funciones
 * anteriores para validar el texto ingresado por un usuario
 */
export function UserInputValidation(value) {
    if (value.includes("@")) {
        if (!isEmailValid(value)) {
            response.isValid = false;
            response.text = "Correo electrónico incompleto. Ejemplo: ejemplo@correo.com";
        }
        else {
            response.isValid = true;
        }
    }
    else if (isNumberValid(value)) {
        if (!isPhoneValid(value)) {
            response.isValid = false;
            response.text = value.length < 10 ? "Teléfono celular incompleto." : "Teléfono celular incorrecto.";
        }
        else {
            response.isValid = true;
        }
    }
    else if (!isCurpValid(value.toUpperCase().trim())) {
        response.isValid = false;
        response.text = value.length < 18 ? "CURP incompleta." : "CURP incorrecta.";
    }
    else {
        response.isValid = true;
    }

    return response;
};

export function PasswordInputValidation(value) {
    const response = { isValid: true, text: "" };

    if (value.length < 8) {
        response.isValid = false;
        response.text = "Tu contraseña debe contener al menos 8 caracteres.";
    } else if (!/[a-z]/.test(value)) {
        response.isValid = false;
        response.text = "Tu contraseña debe contener al menos una letra minúscula.";
    } else if (!/[A-Z]/.test(value)) {
        response.isValid = false;
        response.text = "Tu contraseña debe contener al menos una letra mayúscula.";
    } else {
        response.isValid = true;
    }

    return response;
};
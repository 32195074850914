import { Switch, Route } from "react-router-dom";
import { SettingsProvider } from '../../providers/settings';
import { DataProvider } from '../../providers/data';
import DynamicRoute from './../DynamicRoute';
import HomeLayout from '../../layouts/home-layout';
import Cuentas from "../../containers/Cuentas";

function AppSwitch() {

  return (
    <DataProvider>
      <SettingsProvider>
        <HomeLayout>
          <Switch>
            <Route path={"/SeleccionarCuenta"} name="Seleccionar Cuenta" exact component={Cuentas} />
            <DynamicRoute
              page={path =>
                import(`../../containers${(path !== '/Login') ? path : '/'}`)
                  .then(module => module.default)
                  .catch(async e => {
                    if (/not find module/.test(e.message)) {
                      if (path === '/') {
                        const module = await import('../../containers/Home');
                        return module.default;
                      } else {
                        //To Do: 404
                        const module_1 = await import('../../containers/Example');
                        return module_1.default;
                      }
                    }
                    throw e;
                  })}
            />
          </Switch>
        </HomeLayout>
      </SettingsProvider>
    </DataProvider>
  );
}

export default AppSwitch;

import { createTheme, esES } from "@gti-controls-v2/web-core";
//import { getPath, AVATAR_DEFAULT  } from "../common/utilities/images";

const controlsHeight = 40;
const paleta = {
  //MO 
  azulPrimarioMO: '#0b5c96',

  // Azules
  azul20: "#192C61",
  azul30: "#264391",
  azul40: "#2F52B3",
  azul82: "#B7C5EB",
  azul90: "#D6DEF5",
  // Grises
  gris0: "#000000",
  gris20: "#47495C",
  gris40: "#82849E",
  gris70: "#AFB0C1",
  gris90: "#E5E6EB",
  gris96: "#F4F4F6",
  gris98: "#F9F9FB",
  gris100: "#FFF",
  // Naranjas
  naranja30: "#F56900",
  naranja40: "#FF984A",
  naranja94: "#FFEEE0",
  // Errores
  error20: "#5f2120",
  error30: "#8e1616",
  error40: "#b71c1c",
  error90: "#ffebee",
  // Info
  info20: "#01577e",
  info30: "#027db6",
  info40: "#029ce3",
  info90: "#e7f7fd",
  // Success
  success20: "#265929",
  success30: "#107f42",
  success40: "#139a51",
  success90: "#ecf6ea",
  // Warning
  warning20: "#794f07",
  warning30: "#9b6509",
  warning40: "#c17e0b",
  warning90: "#fff6e5",
  // inProgress
  inProgress20: "#302560",
  inProgress30: "#423385",
  inProgress40: "#5C47B8",
  inProgress90: "#DEDAF1",
};

const silverTheme = createTheme({
//   defaults: {
//     avatar: {
//       img: getPath( AVATAR_DEFAULT ) //https://imagenes.dev09.gtidevops.com/icons/avatar.svg
//     }
//   },
  paleta: paleta, // para exponer los colores por medio tema
  palette: {
    background: {
      default: paleta.gris98,
    },
    primary: {
      main: paleta.azulPrimarioMO,
      dark: paleta.azul30,
      contrastText: paleta.gris100,
    },
    secondary: {
      main: paleta.azul90,
      dark: paleta.azul82,
      light: paleta.azul90,
      contrastText: paleta.gris100,
    },
    error: {
      main: paleta.error40,
      light: paleta.error40,
      dark: paleta.error20,
      contrastText: paleta.gris100,
    },
    warning: {
      main: paleta.warning40,
      light: paleta.warning40,
      dark: paleta.warning20,
      contrastText: paleta.gris100,
    },
    success: {
      main: paleta.success40,
      light: paleta.success40,
      dark: paleta.success20,
      contrastText: paleta.gris100,
    },
    info: {
      main: "#2f54eb",
      light: "#85a5ff",
      dark: "#1d39c4",
      contrastText: "#fff"
    },
  },
  typography: {
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    fontFamily: "Roboto",
    body1: {
      fontSize: "14px",
      lineHeight: "24px",
    },
    body2: {
      fontSize: "0.875rem",
    },
    caption: {
      fontSize: "0.75rem",
    },
    totalizerLabel: {
      fontSize: "16px",
      fontWeight: "normal",
      color: "#828282"
    },
    totalizerResult: {
      fontSize: "16px",
      fontWeight: "normal",
      color: "#4f4f4f"
    }
  },
  status: {
    inactive: {
      bgColor: paleta.gris90,
      textColor: paleta.gris20,
    },
    inProgressWarning: {
      bgColor: paleta.warning40,
      textColor: paleta.gris100,
    },
    inProgress: {
      bgColor: paleta.inProgress40,
      textColor: paleta.gris100,
    },
    done: {
      bgColor: paleta.success40,
      textColor: paleta.gris100,
    },
    active: {
      bgColor: paleta.info40,
      textColor: paleta.gris100,
    },
    cancelled: {
      bgColor: paleta.error40,
      textColor: paleta.gris100,
    },
  },
  styles: {
    success: {
      textColor: paleta.success20,
      iconColor: paleta.success30,
    },
    inProgress: {
      textColor: paleta.inProgress20,
      iconColor: paleta.inProgress30,
    },
    warning: {
      textColor: paleta.warning20,
      iconColor: paleta.warning30,
    },
    default: {
      textColor: paleta.gris20,
      iconColor: paleta.gris40,
    },
    error: {
      textColor: paleta.error20,
      iconColor: paleta.error30,
    },
    info: {
      textColor: paleta.info20,
      iconColor: paleta.info30,
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: paleta.gris100,
          "&": {
            paddingRight: "12px"
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: paleta.gris70,
          },
          "&:hover .MuiOutlinedInput-notchedOutline &:not(.Mui-focused) &:not(.Mui-disabled)":
            {
              borderColor: paleta.gris20,
            },
          "&:not(.MuiInputBase-multiline)": {
            height: controlsHeight,
          },
          ".MuiTableCell-root &": {
            height: `${controlsHeight - 8}px !important`,
            marginTop: "4px",
          },
          "& input:not([type='search'])": {
            height: `${controlsHeight - 17}px !important`,
          },
          "& input[type='search']": {
            height: "32px",
          },
          "& .MuiOutlinedInput-root": {
            borderColor: "#9696AF",
          },
          "& fieldset": {
            top: "0px !important",
            border: "1px solid #9696AF",
          },
          "& fieldset legend": {
            width: "0px",
            display: "none",
          },
          ".Mui-success &:not(.Mui-disabled) fieldset": {
            border: `1px solid ${paleta.success40}`,
          },
          ".Mui-error &:not(.Mui-disabled) fieldset": {
            border: `1px solid ${paleta.error40}`,
          },
          color: paleta.gris20,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          transform: "none !important",
          position: "relative !important",
          fontSize: "14px",
          fontStretch: "normal",
          fontStyle: "normal",
          fontWeight: "500",
          height: "20px",
          lineHeight: "18px",
          color: paleta.gris20,
          "&.Mui-required .MuiFormLabel-asterisk": {
            color: paleta.error40,
          },
          ".Mui-success &:not(.Mui-disabled)": {
            color: paleta.success40,
          },
          ".Mui-error &:not(.Mui-disabled)": {
            color: paleta.error40,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        colorSuccess: {
          color: paleta.gris100,
        },
        colorWarning: {
          color: paleta.gris100,
        },
        colorError: {
          color: paleta.gris100,
        },
        colorInfo: {
          color: paleta.gris100,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& .readOnly-Input": {
            minHeight: controlsHeight,
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            color: paleta.gris20,
          },
        },
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        root: {
          "&.MuiFormGroup-row": {
            height: controlsHeight,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiAutocomplete-clearIndicator": {
            padding: "4px",
            marginRight: "2px",
            color: "rgba(0, 0, 0, 0.54)"
          },
          "& .MuiAutocomplete-popupIndicator": {
            padding: "4px",
            color: "rgba(0, 0, 0, 0.54)"
          },
        },
        option: {
          '&[data-focus="true"]': {
            backgroundColor: "#B9D4E7",
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          "& .MuiAlert-icon": {
            color: paleta.success30,
          },
          color: paleta.success20,
          backgroundColor: paleta.success90,
        },
        standardInfo: {
          "& .MuiAlert-icon": {
            color: paleta.info30,
          },
          color: paleta.info20,
          backgroundColor: paleta.info90,
        },
        standardWarning: {
          "& .MuiAlert-icon": {
            color: paleta.warning30,
          },
          color: paleta.warning20,
          backgroundColor: paleta.warning90,
        },
        standardError: {
          "& .MuiAlert-icon": {
            color: paleta.error30,
          },
          color: paleta.error20,
          backgroundColor: paleta.error90,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: "8px 0 0 !important",
          fontSize: "12px",
          fontWeight: "500",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "1.5",
          letterSpacing: "0.4px",
          color: paleta.gris20,
          ".Mui-success &:not(.Mui-disabled)": {
            color: paleta.success40,
          },
          ".Mui-error &:not(.Mui-disabled)": {
            color: paleta.error40,
          },
        },
      },
    },
    MuiTouchRipple: {
      styleOverrides: {
        child: {
          backgroundColor: "#404040!important",
          color: "#404040",
          opacity: 0.3,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          height: "36px",
          fontSize: "16px",
          lineHeight: "1.5",
          letterSpacing: "1.43px",
          boxShadow: "none",
        },
        sizeSmall: {
          height: "28px",
          fontSize: "14px",
          lineHeight: "1.43",
          letterSpacing: "1.25px",
          paddingRight: "16px",
          paddingLeft: "16px",
        },
        iconSizeSmall: {
          "&> *:first-child": {
            fontSize: "16px",
          },
        },
        iconSizeMedium: {
          "&> *:first-child": {
            fontSize: "18px",
          },
        },
        sizeLarge: {
          height: "44px",
          fontSize: "18px",
          lineHeight: "26px",
          letterSpacing: "1.61px",
          paddingRight: "16px",
          paddingLeft: "16px",
        },
        iconSizeLarge: {
          "&> *:first-child": {
            fontSize: "20px",
          },
        },
        containedPrimary: {
          "&:hover": {
            backgroundColor: paleta.azul30,
          },
          "&:disabled": {
            backgroundColor: "#bbbbbb",
            color: paleta.gris100,
          },
        },
        containedSecondary: {
          backgroundColor: paleta.azul90, //"#e0e0e0",
          color: paleta.azul40, //"#404040",
          "&:hover": {
            backgroundColor: paleta.azul82, //"#c7c7c7",
          },
          "&:disabled": {
            backgroundColor: "#ededed",
          },
        },
        text: {
          color: paleta.azul40,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "#373740",
        },
        colorPrimary: {
          "&:hover": {
            backgroundColor: "#e0e0e0",
          },
        },
        colorSecondary: {
          "&:hover": {
            backgroundColor: "#e0e0e0",
          },
        },
        sizeSmall: {
          color: paleta.azul40,
          borderRadius: 4,
        },
        sizeMedium: {
          color: paleta.azul40,
          padding: "4px",
          borderRadius: 4,
        },
        sizeLarge: {
          color: paleta.azul40,
          padding: "4px",
          borderRadius: 4,
          "&:hover": {
            backgroundColor: paleta.azul90,
            borderRadius: 4,
          },
          "&> *:first-child": {
            fontSize: "24px",
          },
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        anchorOriginTopRight: {
          paddingTop: 20,
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          color: "#000",
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: "20px",
          "&.Mui-disabled": {
            color: "#bcbccc",
          },
        },
        colorPrimary: {
          color: "#0b5c95",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          "&$select": {
            padding: "11px 14px",
          },
          "&$icon": {
            color: "#000",
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          "&[disabled]": {
            color: "#bcbccc",
            cursor: "default",
            "&:hover": {
              textDecoration: "none",
            },
          },
        },
      },
    },
    MuiPickersBasePicker: {
      styleOverrides: {
        pickerView: {
          //backgroundColor:"black"
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: paleta.azul40,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "none",
          color: paleta.gris20,
          backgroundColor: "transparent",
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: paleta.gris90,
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            fontWeight: 500,
          },
          "& .MuiDataGrid-pinnedColumnHeaders": {
            backgroundColor: paleta.gris90,
            fonrWeight: 500
          },
          "& .MuiDataGrid-pinnedColumns--right": {
            zIndex: "auto",
          },
          "& .MuiDataGrid-columnHeaders .header-alt": {
            backgroundColor: "#d1d3dc",
          },
          "& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitleContainer": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-iconSeparator": {
            display: "none",
          },
          "& .MuiDataGrid-row a": {
            color: paleta.azul40,
            "&:hover": {
              color: paleta.azul30,
            },
            "&:disabled": {
              color: "#83c2e5",
            },
          },
          "& .MuiDataGrid-sortIcon": {
            opacity: "inherit !important",
            color: `${paleta.azul40} !important`,
            "&:hover": {
              color: `${paleta.azul30} !important`,
            },
            "&:disabled": {
              color: "#83c2e5",
            },
          },
          "& .MuiDataGrid-row button": {
            color: "#0b5c96",
            "&:hover": {
              backgroundColor: '#aad9fd !important',
              color: "#0b5c96",
            },
            "&:disabled": {
              color: "#83c2e5",
            },
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04) !important",
            cursor: "pointer",
          },
          "& .MuiDataGrid-row.Mui-selected": {
            backgroundColor: `${paleta.azul90} !important`,
          },
          "& .MuiDataGrid-row.Mui-selected .MuiDataGrid-cell": {
            borderBottom: `1px solid ${paleta.gris90}`,
          },
          "& .MuiDataGrid-row .MuiDataGrid-cell.border-left": {
            borderLeft: `1px solid ${paleta.gris90}`,
          },
          "& .MuiDataGrid-row .MuiDataGrid-cell.border-right": {
            borderRight: `1px solid ${paleta.gris90}`,
          },
          "& .MuiDataGrid-columnHeader:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeader:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-detailPanel": {
            zIndex: "auto",
            backgroundColor: paleta.gris96,
            borderBottom: "1px solid rgb(0 0 0 / 14%)",
          },
          "& .MuiDataGrid-detailPanel .MuiBox-root .MuiDataGrid-root": {
            backgroundColor: paleta.gris98,
          },
          "& .MuiDataGrid-row--lastVisible": {
            borderBottom: "1px solid rgb(0 0 0 / 14%)",
          },
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          "& ul > li > button:not(.Mui-selected)": {
            "&:hover": {
              backgroundColor: paleta.azul90,
            },
            backgroundColor: "transparent",
            color: paleta.azul40,
            fontWeight: 500,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          whiteSpace: "normal",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          "& .MuiCardHeader-root": {
            borderBottom: `1px solid ${paleta.gris90}`,
          },
          "& .MuiCardHeader-title": {
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "normal",
            color: paleta.gris20,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: paleta.azul40,
          height: 3,
        },
        root: {
          "& .MuiTab-root": {
            borderBottom: "2px solid transparent",
            "&:hover": {
              backgroundColor: paleta.gris96,
            },
          },
          borderBottom: `1px solid ${paleta.gris90}`,
          color: paleta.gris40,
        },
      },
    },
  },
}, esES);

export default silverTheme;
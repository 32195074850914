import React, { useState, useEffect } from 'react';

const AsyncComponent = (props) => {
  const [Component, setComponent] = useState(null);

  useEffect(() => {
    let cleanedUp = false
    props.component
      .then(component => {
        if (cleanedUp) {
          return
        }
        setComponent(() => component)
      })
      .catch(e => {
        if (!cleanedUp) {
          setComponent(null)
        }
        if (typeof props.onError === 'function') {
          props.onError(e, props.history)
          return
        }
        throw e
      })
    return () => {
      setComponent(null)
      cleanedUp = true
    }
  }, [props.path, props.search, props])

  return Component ? React.createElement(Component, props.otherProps) : props.loading;
}
export default AsyncComponent;